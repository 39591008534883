<template>
  <div class="col-md-12">
    <div
      class="modal fade modal_cust show"
      v-if="modalId == 'newFeature'"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle1"
      style="display: block"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered modal_ac_head"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalCenterTitle">
             Add New Feature
            </h5>
            <a class="close" @click="$store.dispatch('modalClose', 'newFeature')" title="Close">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <form
            @submit.prevent="submit"
            method="post"
            class="bg-theam-secondary"
            enctype="multipart/form-data"
          >
            <div class="modal-body row">
              <div class="col-md-12">
                <div class="group mb-3">
                  <label class="group-label">Basic Information</label>
                  <div class="group-attribute-container">
                    <div class="row mb-2">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Title *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Name of the feature"
                              v-model="feature.name"
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-md-5 mr-0 pr-0">Status *</div>
                          <div class="col-md-7 ml-0 pl-0">
                            <select
                              class="form-control"
                              required
                              v-model="feature.status"
                            >
                              <option value="1">Active</option>
                              <option value="0">In-active</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right mt-3">
                <button type="submit" class="btn btn-sm btn-primary" :disabled="disableSubmitButton">
                 {{ disableSubmitButton ? "Submitting ...." : "Submit"}}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Services from "./Services/Services";
export default {
  data() {
    return {
      feature: {
          'name': '',
          'status': 0
      },
      disableSubmitButton: false
    };
  },
  computed: {
    ...mapGetters([
      "processing",
      "eventMessage",
      "dataLists",
      "dataId",
      "modalId",
      "modalMode",
    ]),
  },
  methods: {
      submit(){
        //   console.log(this.feature)
          this.disableSubmitButton = true
          Services.createNewFeature(this.feature)
          .then(res => {
            this.setNotification(res.data.success_message);
            this.disableSubmitButton = false
            this.$store.dispatch("modalClose");
            this.$emit('parent-event')
          })
          .catch(err => {
              console.log(err.response.status)
              if(err.response.status == 422){
                let error = Object.values(err.response.data.errors).flat();
                this.setNotification(error);
             }else{
                let error = err.response.data.error_message;
                this.setNotification(error);
             }
          })
          .finally(() => {
              this.disableSubmitButton = false
          })
      },

      setNotification(msg){
        this.$store.dispatch("enableMessage");
        this.$store.commit("setEventMessage",msg);
    },
  },
  watch: {

  },
};
</script>
