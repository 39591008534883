import Api from "../../../store/Services/Api";
import {store} from "../../../store/index";
class FeatureService{
    getFeatureList(param){
        // url to be called in backend
        const url = `api/plan-price/feature`
        //parameter
        const params = {
            'page-index':param.pageIndex,
            'searched':param.searched,
            'offset':param.offset
        }
        //headers
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        //api calling to api.js function
        return Api.get(url,headers,params);
    }

    createNewFeature(param) {
        const url = `api/plan-price/feature`
        const headers={
            'Accept':'application/json',
            'x-Request-with': 'XMLHttpRequest',
            'Authorization': 'Bearer ' + store.getters['token'],
        }
        return Api.post(url,param,headers)
    }
}
export default new FeatureService;