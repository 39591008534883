<template>
  <div class="content-body">
    <div class="row mg-0">
      <div class="col-sm-5">
        <div class="content-header pd-l-0">
          <div>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="#">Product Setup</a></li>
                <li
                  class="breadcrumb-item text-capitalize active"
                  aria-current="page"
                >
                  Features
                </li>
              </ol>
            </nav>
            <h4 class="content-title content-title-xs">Manage Features</h4>
          </div>
        </div>
      </div>
      <div class="col-sm-0 tx-right col-lg-7">
        <button
          type="button"
          class="btn btn-primary mg-t-8 mg-r-0 mg-b-0"
          @click="
            $store.dispatch('modalWithMode', { id: 'newFeature', mode: 'create' })
          "
        >
          New Feature
        </button>
      </div>
      <!-- col -->
    </div>
    <!-- row -->
    <div class="bg-theam-secondary table-search-head">
      <div class="row">
        <div class="filter-input col-md-9 d-flex mr-0 pr-0" >
          <select v-model="params.pageIndex" @change="searchFilter">
            <option value="2">2</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            <option value="500">500</option>
          </select>
        </div>
        <div class="col-md-3 ml-0 pl-0">
          <input type="text" class="float-right" placeholder="Search..." v-model="params.searched" @keyup="searchKeyword"/>
        </div>
      </div>
    </div>
    <div class="row py-0">
      <div class="col-md-12">
        <table class="table table2 table-hover" id="tableData">
          <thead>
            <tr>
              <th scope="col" class="text-center">S.N</th>
              <th scope="col">Plan</th>
              <th scope="col">No. of Branch</th>
              <th scope="col">No. of User</th>
              <th scope="col">Price</th>
              <th scope="col" class="text-center">Action</th>
            </tr>
          </thead>
          <tbody v-if="!loading && featureLists.length > 0">
            <tr v-for="(feature,index) in featureLists" :key="index">
              <th scope="row" class="text-center">{{index+pageSerialNo}}</th>
              <td>{{feature.name}}</td>
              <td>{{feature.name}}</td>
              <td>{{feature.name}}</td>
              <td>{{feature.name}}</td>
              <td class="text-center">
                <a href="" title="More about plan details" class="mr-3">
                  <i class="far fa-edit"></i>
                </a>
                <a href="" title="Drop Plan">
                  <i class="fa fa-trash"></i>
                </a>
              </td>
            </tr>
          </tbody>
           <tbody v-else-if="!loading && featureLists.length == 0">
            <tr>
              <td  colspan="11" style="text-align:center;">
                No records found.
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="loading">
            <tr>
              <td  colspan="11" style="text-align:center;">
                Loading.....
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="error">
            <td  colspan="11" style="text-align:center;">
                Error Fetching data from the server.
              </td>
          </tbody>
        </table>
      </div>
    </div>
      <Paginate v-model="page" :pageCount="totalPageCount"
          :containerClass="'pagination'"
          :clickHandler="clickCallback"
          v-if="totalPageCount > 1"
      />
    <Create @parent-event="getData()" />
  </div>
</template>

<script>
import Create from "./create";
import Services from "./Services/Services";
import Paginate from "vuejs-paginate";
import { mapGetters } from "vuex";
export default {
  components: { Create , Paginate},
  computed: {
    ...mapGetters([
      "icons",
      "modalMode",
      "dataId",
      "dataLists",
      "dataLists9",
      "removeList",
      "pagination",
      "eventMessage",
    ]),
    ...mapGetters("features", ["featureLists"]),
  },
  data() {
    return {
      params:{
        pageIndex:2,
        searched:'',
        offset:0,
      },
      loading :false,
      error:false,
      pageSerialNo:1,
      page:1,
      totalPageCount:0,
    };
  },
  mounted(){
    this.getData();
  },
  methods: {
     clickCallback: function(page) {
      if(page == 1){
        this.pageSerialNo = 1;
      }else{
        this.pageSerialNo = ((page-1) * this.params.pageIndex) + 1 ;
      }
      this.params.offset = (page-1) * this.params.pageIndex;
      this.getData();
    },
    getData(){
      this.loading = true;
      Services.getFeatureList(this.params).then(res=>{
          this.$store.commit("features/setFeatureLists",res.data.data.data);
          this.totalPageCount = res.data.data.total_no_of_pages;
      }).catch(err=>{
        console.log(err);
        this.error = true;
      }).finally(()=>{
        this.loading =false;
      });
    },
    searchFilter(){
      this.params.offset = 0;
      this.pageSerialNo= 1;
      this.page =1;
      this.getData();
    },
    searchKeyword(){
      console.log("dsa")
    }
  },
};
</script>
